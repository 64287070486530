Dropzone.autoDiscover = false;

jQuery(function () {
    Dropzone.autoDiscover = false;

    $('#ru_filter_date_from').datetimepicker({
        locale: 'de',
        format: 'L'
    });
    $('#ru_filter_date_to').datetimepicker({
        locale: 'de',
        format: 'L'
    });

    $(".wysiwyg-editor").each(function (i, el) {
        /*
        Default:
        config.toolbarGroups = [
            { name: 'document',    groups: [ 'mode', 'document', 'doctools' ] },
            { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
            { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
            { name: 'forms' },
            '/',
            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
            { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
            { name: 'links' },
            { name: 'insert' },
            '/',
            { name: 'styles' },
            { name: 'colors' },
            { name: 'tools' },
            { name: 'others' },
            { name: 'about' }
        ];
         */
        const ckeditorConfig = {
            /*
            coreStyles_strike: {
                element: 'span',
                attributes: {'class': 'strike'},
                overrides: 'strike'
            },
            coreStyles_underline: {
                element: 'span',
                attributes: {'class': 'underline'}
            },

            removePlugins: 'stylescombo,save,showblocks,specialchar,about,preview,pastetext,magicline,liststyle',
            extraPlugins: 'tabletools',
            enterMode: enterMode,
            filebrowserBrowseUrl: '/',
            uploadUrl: '/',
             */
            removePlugins: 'scyat,elementspath,resize,specialchar,autogrow',
            toolbarGroups: [
                {name: 'basicstyles', groups: ['basicstyles', 'insert']},
                {name: 'links'},
                {name: 'tools'}
            ],
            allowedContent: 'p(image-center); strong em; img[alt,title,src,width,height](image-left,image-center,image-right); a[href]; br',
            contentsCss: '/css/editorstyles.css',
            image2_alignClasses: [ 'image-left', 'image-center', 'image-right' ],
            image2_captionedClass: 'image-captioned',
            image2_altRequired: true,
            height: 600,
            image2_maxSize: {
                height: 800,
                width: 650
            }
        };
        const uploadUrl = $(el).data("upload-url");
        if (uploadUrl) {
            ckeditorConfig['filebrowserUploadUrl'] = uploadUrl;
        }
        const editor = CKEDITOR.replace(el, ckeditorConfig);
        editor.on('fileUploadRequest', (evt) => {
            evt.data['requestData']['_token'] = $("input[type=hidden][name=_token]").val();
        });
    });


    $("#loc_mhp_id").change(function () {
        if ($(this).val() == '') {
            $("#mhp_link").html("&nbsp;");
        } else {
            var url = 'http://branchenbuch.portal.muenchen.de/mhp/' + $(this).val() + '/';
            $("#mhp_link").html($("<a href='' target='_blank' rel='noopener noreferrer'></a>").attr("href", url).text(url));
        }
    });

    if ($('.location-edit-dialog').length > 0) {
        new LocationEditDialog();
    }
    if ($('.news-edit-form').length > 0) {
        new NewsEditForm();
    }
    if ($("#new-location-form").length > 0) {
        new LocationForm($("#new-location-form"));
    }

    $(".create_html input[name=create_html]").change(function() {
        if (parseInt($(".create_html input[name=create_html]:checked").val()) === 2) {
            if (!confirm("Sind Sie sicher? Alle händisch getätigten Änderungen der hochgeladenen Rathaus Umschau werden hierdurch überschrieben.")) {
                $(".create_html input[name=create_html]:checked").prop("checked", false);
                $(".create_html input[name=create_html][value=1]").prop("checked", true);
            }
        }
    });

    const $ruUpload = $("#ru-upload");
    if ($ruUpload.length > 0) {
        $ruUpload.find(".dropzone").dropzone({
            url: $ruUpload.attr("action"),
            params: () => {
                return {
                    '_token': $ruUpload.find("input[name=_token]").val(),
                    'create_html': $ruUpload.find("input[name=create_html]:checked").val(),
                };
            },
            accept: (file, done) => {
                var name = file.name.split(".");
                if (name.length === 2 && name[1] === "pdf" && name[0].match(/^\d+$/)) {
                    done();
                } else {
                    alert("Die Datei muss den Dateinamen [RU-Nummer].pdf haben.");
                    done("Die Datei muss den Dateinamen [RU-Nummer].pdf haben.");
                }
            },
            dictDefaultMessage: 'Das PDF hierher ziehen',
            success: (data) => {
                const output = JSON.parse(data.xhr.response);
                $("#ru-upload-result").show().text(output['output']);
                $("#ru-upload").hide();
            },
            error: (err, output) => {
                alert(output['output']);
            }
        });
    }
});
