class NewsEditForm {

    initPersonsSelect() {
        this.$newsPersions = $("#newsPersons");
        this.$newsPersions.select2({
            allowClear: true,
            language: 'de',
            ajax: {
                url: this.$newsPersions.data("typeahead-src"),
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data,
                        pagination: {
                            more: false
                        }
                    };
                },
                cache: true
            },
            minimumInputLength: 1
        });
    }

    initReferateSelect() {
        this.$newsReferate = $("#newsReferate");
        this.$newsReferate.select2({
            allowClear: true,
            language: 'de',
            ajax: {
                url: this.$newsReferate.data("typeahead-src"),
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        q: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data,
                        pagination: {
                            more: false
                        }
                    };
                },
                cache: true
            },
            minimumInputLength: 1
        });
    }

    initLocationField() {
        this.$locationRow = $(".location-row");
        this.$locationInput = $("#newsLocation");
        this.$locationInput.typeahead({
            minLength: 3,
            limit: 10
        }, {
            name: 'streets',
            display: 'title',
            limit: 100,
            source: new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                // prefetch: '../data/films/post_1960.json',
                remote: {
                    url: this.$locationInput.data("typeahead-src"),
                    wildcard: '%QUERY'
                }
            }),
            templates: {
                suggestion: function (data) {
                    var htmlEntities = function (str) {
                        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
                    };

                    var str = '<div>';
                    str += htmlEntities(data.title);
                    if (typeof (data.address) != 'undefined') {
                        str += '<div class="address">' + htmlEntities(data.address) + '</div>';
                    }
                    str += '</div>';
                    return str;
                }
            }
        }).on("typeahead:select", (ev, item) => {
            if (item.type === 'algolia') {
                this.$locationRow.addClass("has-branchenbuch");
                this.$locationRow.find("input[name=mhp_id]").val(item.id);
                this.$locationRow.find(".title").text(item.title + " (" + item.address + ")");
                this.setLocation(item.lat, item.lng, true);
            }
            this.onLocationChange();
        }).change(() => {
            this.onLocationChange()
        });

        $("#branchenbuchHolder").find(".remove").click((ev) => {
            ev.preventDefault();
            this.$locationRow.removeClass("has-branchenbuch");
            this.$locationRow.find("input[name=mhp_id]").val(0);
            this.$locationRow.find(".title").text("");
            this.$locationInput.val("").focus();
        });
    }

    initLocationMap() {
        this.$locationLat = $("input[name=location_lat]");
        this.$locationLng = $("input[name=location_lng]");
        var center = [52.52112, 13.40554];
        if (this.$locationLat.val() > 0) {
            center = [
                this.$locationLat.val(),
                this.$locationLng.val()
            ];
        }
        this.locationEditMap = L.map('loc_map', {
            zoomControl: true,
            zoomControlPosition: 'top-left',
            inertia: false,
            center: center,
            zoom: 16,
            maxZoom: 18,
            minZoom: 11
        });
        L.tileLayer('https://api.mapbox.com/styles/v1/gradl/cj9419pn16l302soajzphlwvd/tiles/256/{z}/{x}/{y}{highres}?access_token=pk.eyJ1IjoiZ3JhZGwiLCJhIjoiY2pzeDg5aWFoMGw2YTQ0cDZxZGs4cGMydSJ9.zSlyhYGJCogHy3qEqveHNw', {
            maxZoom: 20,
            attribution: '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a>, <a href="http://mapbox.com">Mapbox</a>, <a href="https://www.mapbox.com/map-feedback/">Improve this map</a>',
            highres: (window.devicePixelRatio > 1.5 ? '@2x' : ''),
        }).addTo(this.locationEditMap);

        if (this.$locationLat.val() > 0) {
            this.locationMapMarker = L.marker(center, {
                icon: L.icon({iconUrl: '/images/marker-icon.png', iconSize: [25, 41], iconAnchor: [13, 41]}),
            }).addTo(this.locationEditMap);
        } else {
            this.locationMapMarker = null;
        }
        this.locationEditMap.on("click", (e) => {
            this.setLocation(e.latlng.lat, e.latlng.lng, false);
        });
    }

    setLocation(lat, lng, center) {
        if (this.locationMapMarker) {
            this.locationEditMap.removeLayer(this.locationMapMarker);
        }
        this.locationMapMarker = L.marker([lat, lng], {
            icon: L.icon({iconUrl: '/images/marker-icon.png', iconSize: [25, 41], iconAnchor: [13, 41]}),
        }).addTo(this.locationEditMap);
        this.$locationLat.val(lat);
        this.$locationLng.val(lng);
        if (center) {
            this.locationEditMap.setView([lat, lng], 16);
        }
    }


    onLocationChange() {
        if (this.$locationRow.find("input[name=mhp_id]").val() != "") {
            return;
        }
        $.get(this.$locationInput.data("geo-src"), {query: this.$locationInput.val()}, (ret) => {
            if (ret) {
                this.setLocation(ret["lat"], ret["lng"], true);
            }
        });
    }

    constructor() {
        this.initLocationField();
        this.initPersonsSelect();
        this.initReferateSelect();
        this.initLocationMap();
    }
}
