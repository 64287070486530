class AddressSelectField {
    constructor($field) {
        this.$element = $field;
        this.$element.typeahead({
            minLength: 3,
            limit: 10
        }, {
            name: 'streets',
            display: 'title',
            limit: 100,
            source: new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                // prefetch: '../data/films/post_1960.json',
                remote: {
                    url: this.$element.data("typeahead-src"),
                    wildcard: '%QUERY'
                }
            })
        });
    }
}
