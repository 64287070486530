class LocationEditDialog {
    locationTypeahead() {
        $('.location-typeahead').typeahead(null, {
            name: 'locations',
            display: 'title',
            limit: 10,
            source: new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                // prefetch: '../data/films/post_1960.json',
                remote: {
                    url: '/locationsearch/?query=%QUERY',
                    wildcard: '%QUERY'
                }
            })
        }).on('typeahead:selected', (ev, item) => {
            if (item['mhp_id']) {
                this.onTypeaheadSelectedMHP(item);
            } else {
                this.onTypeaheadSelectedNone();
            }
        });
    }

    onTypeaheadSelectedMHP(item) {
        $('.location-edit-dialog').find('.row-title').addClass('hidden');
        //$('.location-edit-dialog').val('.row-mhp').removeClass('hidden');

        $("#loc_mhp_id").val(item['mhp_id']).prop("disabled", true);
        $("#mhp_link").html($("<a href='' target='_blank' rel='noopener noreferrer'></a>").attr("href", item['mhp_link']).text(item['mhp_link']));
        $("#loc_address").val(item['address']).prop("disabled", true);
        $("#loc_plz").val(item['plz']).prop("disabled", true);
        $("#loc_city").val(item['ort']).prop("disabled", true);

        $("#loc_map").addClass("visible");

        if (!this.locationEditMap) {
            this.locationEditMap = L.skobbler.map('loc_map', {
                apiKey: 'bf2aa5fde747ceb611e6a0ef8f5ade6e',

                mapStyle: 'day',
                bicycleLanes: false,
                onewayArrows: false,
                pois: 'all',
                primaryLanguage: 'de',
                fallbackLanguage: 'en',
                mapLabels: 'localNaming',
                retinaDisplay: 'auto',

                zoomControl: true,
                zoomControlPosition: 'top-left',

                center: [52.52112, 13.40554],
                zoom: 16
            });
        }

        if (this.locationMapMarker) {
            this.locationEditMap.removeLayer(this.locationMapMarker);
            this.locationMapMarker = null;
        }
        if (item.lat) {
            this.locationMapMarker = L.marker([item.lat, item.lng]).addTo(this.locationEditMap);
            this.locationMapMarker.bindPopup("<b>" + item.title + "</b>").openPopup();
            this.locationEditMap.setView([item.lat, item.lng], 16, true);
        }
    }

    onTypeaheadSelectedNone() {
        $('.location-edit-dialog').find('.row-title').removeClass('hidden');

        $("#loc_mhp_id").val('').prop("disabled", false);
        $("#mhp_link").html("&nbsp;");
        $("#loc_address").val('').prop("disabled", false);
        $("#loc_plz").val('').prop("disabled", false);
        $("#loc_city").val('').prop("disabled", false);

        if (this.locationMapMarker) {
            this.locationEditMap.removeLayer(this.locationMapMarker);
            this.locationMapMarker = null;
        }
    }

    constructor() {
        this.locationEditMap = null;
        this.locationMapMarker = null;
        this.locationTypeahead();
    }
}